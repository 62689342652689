@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
* {
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    padding: 0;
}

h2 {
    font-weight: 900;
    font-size: 4em;
    font-family: 'DM Sans', sans-serif;
}

h5 {
    font-size: 1.2em;
}

.white {
    color: #ffffff;
}

header {
    position: absolute;
    top: 0;
    width: 10vw;
    height: 100vh;
    padding: 0.5em;
    min-width: 12em;
}

nav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    gap: 2em;
}

.nav-logo {
    width: 40px;
    height: 40px;
    color: #ffffff;
}

video {
    min-width: 100vw;
    min-height: 100vh;
    object-fit: cover;
    z-index: -999;
}

.title {
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.container-content {
    background-color: #202124;
    width: 90vw;
}

.hero-section {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
}

.hero-section-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60vw;
    height: 90vh;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

}

.hero-section-inner-title {
    margin-left: 10vw;
    margin-top: 10vw;
    margin-bottom: 2vh;
    font-size: 8em;
    line-height: 1em;
    color: #080818;
}

.hero-section-inner-content {
    margin-left: 10vw;
    font-size: 1.4em;
    color: #080818;
    font-weight: 900;
}

@media screen and (max-width: 850px) {
    .hero-section-inner {
        width: 100vw;
    }

    .hero-section-inner-title {
        font-size: 15vw;
    }
}
/*# sourceMappingURL=index.508462c5.css.map */
